// color variants
@import 'themes-vars.module.scss';
@import 'custom-fonts.scss';

// third-party
// @import '~react-perfect-scrollbar/dist/css/styles.css';

// @function calculateTypeOffset($lh, $fontSize, $descenderHeightScale) {
//     $lineHeightScale: $lh / $fontSize;
//     @return ($lineHeightScale - 1) / 2 + $descenderHeightScale;
// }

// @mixin basekick($typeSizeModifier, $baseFontSize, $descenderHeightScale, $typeRowSpan, $gridRowHeight, $capHeight) {
//     $fontSize: $typeSizeModifier * $baseFontSize;
//     $lineHeight: $typeRowSpan * $gridRowHeight;
//     $typeOffset: calculateTypeOffset($lineHeight, $fontSize, $descenderHeightScale);
//     $topSpace: $lineHeight - $capHeight * $fontSize;
//     $heightCorrection: 0;

//     @if $topSpace > $gridRowHeight {
//         $heightCorrection: $topSpace - ($topSpace % $gridRowHeight);
//     }

//     $preventCollapse: 1;

//     font-size: #{$fontSize}px;
//     line-height: #{$lineHeight}px;
//     transform: translateY(#{$typeOffset}em);
//     padding-top: $preventCollapse;

//     &::before {
//         content: '';
//         margin-top: #{- ($heightCorrection + $preventCollapse)}px;
//         display: block;
//         height: 0;
//     }
// }

// disable text selection outside of input fields, textareas, and selects
// import 'mapbox-gl/dist/mapbox-gl.css';
// import the css for the mapbox-gl library

* {
    scrollbar-width: thin !important;
    scrollbar-color: $primaryLight $grey50;
}
html {
    min-height: 100vh;
    scrollbar-width: thin !important;
    scrollbar-color: $primaryLight $grey50;
    background-color: $grey50 !important;
}
:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: $grey50 !important;
}
body {
    min-width: 100%;
    min-height: 100vh !important;
    background-color: $grey50 !important;
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;

    // -webkit-touch-callout: none;
    // -webkit-tap-highlight-color: transparent;
}

// @supports not selector(::-webkit-scrollbar) {

// }

// disable tap highlight
svg,
button,
div,
input,
textarea,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

*::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scrollbars */
    height: 5px; /* Mostly for horizontal scrollbars */
}

*::-webkit-scrollbar-thumb {
    /* Foreground */
    background: $primaryLight;
}
*::-webkit-scrollbar-track {
    /* Background */
    background: $grey10;
}
*::selection {
    background: $primaryLight;
    color: $paper; /* WebKit/Blink Browsers */
}

*::-moz-selection {
    background: $primaryLight;
    color: $paper; /* WebKit/Blink Browsers */
}

.moduleDragged {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    border-top: none;
    border-bottom: none;
    border: 1px solid $primaryMain;
}

div::-moz-selection {
    background: transparent;
    color: transparent;
}
div::selection {
    background: transparent;
    color: transparent;
}
img::-moz-selection {
    background: transparent;
    color: transparent;
}
img::selection {
    background: transparent;
    color: transparent;
}
svg::selection {
    background: transparent;
    color: transparent;
}
svg::-moz-selection {
    background: transparent;
    color: transparent;
}

p {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    // highlight for all browsers

    // }
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
}

.simpleAutocompleteInput {
    width: 100% !important;
    // height: 2.5rem !important;
    padding: 0 0.5rem 0 0.5rem !important;
    // display: flex;
    // justify-content: stretch;
    // align-items: center;
    // justify-content: center;
    .MuiFormHelperText-root {
        color: $errorMain !important;
        margin-bottom: 0.25rem !important;
        font-weight: 500 !important;
        font-size: 0.875rem !important;
    }
    .MuiSelect-select {
        padding: 0;
        background: none;
        .MuiTypography-root {
            font-weight: 500 !important;
        }

        &:focus,
        &:active,
        &:hover {
            background: none;
        }
        &:focus,
        &:active {
            > * {
                color: $primaryMain !important;
            }
        }

        &.Mui-disabled {
            color: $grey500 !important;
            -webkit-text-fill-color: $grey500 !important;
            > * {
                color: $grey500 !important;
                -webkit-text-fill-color: $grey500 !important;
            }
        }
    }
    // .MuiInput-root {
    //     margin: 0 0 !important;
    //     display: flex !important;
    //     justify-content: stretch !important;
    //     font-size: 1rem;
    //     font-weight: 500 !important;
    //     min-height: 2.5rem !important;
    //     width: 100% !important;
    //     padding: 0.25rem 2rem 0.25rem 0.5rem !important;
    //     color: $grey600 !important;
    //     // right gradient

    //     // &.Mui-disabled {
    //     //     color: $primaryMain !important;
    //     //     // -webkit-text-fill-color: $primaryMain !important;
    //     // }
    //     & > .Mui-disabled {
    //         // color: $primaryMain !important;
    //         -webkit-text-fill-color: $grey500 !important;
    //     }

    //     &:active,
    //     :focus {
    //         font-weight: 500 !important;

    //         color: $primaryMain !important;
    //     }
    //     & > ::placeholder {
    //         color: $grey200;
    //         font-size: 1rem;
    //         opacity: 1;
    //         font-weight: 500 !important;
    //     }
    //     > * {
    //         margin: 0 !important;
    //     }
    // }
    // .MuiAutocomplete-root {
    //     width: 100% !important;
    //     height: 2.5rem !important;
    // }
}
.simpleFormDatePicker {
    width: 100% !important;
    height: 2.5rem !important;
    border-radius: 1rem !important;

    & > .MuiPickersLayout-root {
        background: red !important;
        width: 200px !important;
        height: 40px !important;
    }
    .MuiFormHelperText-root {
        color: $errorMain !important;
        margin-bottom: 0.25rem !important;
        font-weight: 500 !important;
        font-size: 0.875rem !important;
    }
    .MuiPickersPopper-root {
        box-shadow: none !important;
    }
    .MuiInputBase-root {
        &.Mui-disabled {
            color: $grey500 !important;
            -webkit-text-fill-color: $grey500 !important;
            > * {
                color: $grey500 !important;
                -webkit-text-fill-color: $grey500 !important;
            }
        }
        color: $grey600 !important;
        height: 2.5rem !important;
        width: 100% !important;
        padding: 0;
        border: none !important;
        outline: none !important;
        border-radius: 0.75rem !important;
        background: none;
        > * {
            font-weight: 500 !important;
        }
        &:focus,
        &:active,
        &:hover {
            background: none;
        }
        &:focus,
        &:active {
            > * {
                color: $primaryMain !important;
            }
        }
    }
    // .MuiInput-root {
    //     border: none !important;
    //     outline: none !important;
    //     border-color: none !important;
    //     margin: 0 0 !important;
    //     display: flex !important;
    //     justify-content: stretch !important;
    //     font-size: 1rem;
    //     font-weight: 500 !important;
    //     height: 2.5rem !important;
    //     width: 100% !important;
    //     padding: 0.25rem 0 0.25rem 0 !important;
    //     color: $grey600 !important;
    //     // &.Mui-disabled {
    //     //     color: $primaryMain !important;
    //     //     // -webkit-text-fill-color: $primaryMain !important;
    //     // }
    //     & .Mui-disabled {
    //         color: $grey200 !important;
    //         -webkit-text-fill-color: $grey200 !important;
    //         > * {
    //             color: $grey200 !important;
    //             -webkit-text-fill-color: $grey200 !important;
    //         }
    //     }

    //     &:active,
    //     :focus {
    //         font-weight: 500 !important;

    //         color: $primaryMain !important;
    //     }
    //     & > ::placeholder {
    //         color: $grey200;
    //         font-size: 1rem;
    //         opacity: 1;
    //         font-weight: 500 !important;
    //     }
    //     > * {
    //         margin: 0 !important;
    //     }
    // }
}

.MuiAutocomplete-root {
    .MuiAutocomplete-endAdornment {
        top: 50% !important;
    }
}
.MuiAutocomplete-popper {
    div {
        background: $grey5 !important;
        box-shadow: none !important;
        border-radius: 1rem !important;
        overflow: hidden !important;
        max-height: 200px !important;
        // overflow-y: auto !important;
        background: $grey5 !important;
        // background: rgba(255, 255, 255, 0.75) !important;
        // backdrop-filter: blur(1rem) !important;
    }
}
.simpleFormInput {
    width: 100% !important;
    height: 2.5rem !important;
    padding: 0 0.5rem 0 1rem !important;
    // display: flex;
    // justify-content: stretch;
    // align-items: center;
    // justify-content: center;
    .MuiFormHelperText-root {
        color: $errorMain !important;
        margin-bottom: 0.25rem !important;
        font-weight: 500 !important;
        font-size: 0.875rem !important;
    }
    .MuiSelect-select {
        padding: 0;
        background: none;
        .MuiTypography-root {
            font-weight: 500 !important;
        }

        &:focus,
        &:active,
        &:hover {
            background: none;
        }
        // &:focus,
        // &:active {
        //     > * {
        //         color: $paper !important;
        //     }
        // }

        // &.Mui-disabled {
        //     color: $grey500 !important;
        //     -webkit-text-fill-color: $grey500 !important;
        //     > * {
        //         color: $grey500 !important;
        //         -webkit-text-fill-color: $grey500 !important;
        //     }
        // }
    }
    // .MuiInput-root {
    //     margin: 0 0 !important;
    //     display: flex !important;
    //     justify-content: stretch !important;
    //     font-size: 1rem;
    //     font-weight: 500 !important;
    //     height: 2.5rem !important;
    //     width: 100% !important;
    //     padding: 0.25rem 0.5rem 0.25rem 0 !important;
    //     color: $grey600 !important;
    //     // right gradient

    //     // &.Mui-disabled {
    //     //     color: $primaryMain !important;
    //     //     // -webkit-text-fill-color: $primaryMain !important;
    //     // }
    //     & > .Mui-disabled {
    //         // color: $primaryMain !important;
    //         -webkit-text-fill-color: $grey500 !important;
    //     }

    //     &:active,
    //     :focus {
    //         font-weight: 500 !important;

    //         color: $paper !important;
    //     }
    //     & > ::placeholder {
    //         color: $grey200;
    //         font-size: 1rem;
    //         opacity: 1;
    //         font-weight: 500 !important;
    //     }
    //     > * {
    //         margin: 0 !important;
    //     }
    // }
    // .MuiAutocomplete-root {
    //     width: 100% !important;
    //     height: 2.5rem !important;
    // }
}

.simpleFormTextarea {
    width: 100% !important;
    transition: all 0.1s ease-in-out;
    // padding: 0 0 0 1rem;
    border-radius: 1rem !important;
    overflow: hidden;
    textarea {
        padding: 0.5rem 1rem !important;
        overflow-x: hidden;

        // overflow: hidden;
    }
}
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-error {
    color: $secondaryDark !important;
}
.MuiInputBase-root.Mui-error:after {
    border-bottom-color: $errorLight !important;
}
.MuiInputBase-root:before {
    border-bottom: none !important;
}
// .MuiFormHelperText-root {
//     color: $primaryLight !important;
// }

.swiper-pagination-bullets {
    bottom: 0 !important;
}
// .swiper-pagination:not(.is-desktop) {
//     display: none;
// }
.timer-pager.swiper-pagination-bullet {
    display: flex;
    z-index: 5 !important;
    // background: 'red',
    // background: $paper;

    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(0.5rem) contrast(0.75);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    opacity: 1;
    padding: 0 1rem;
    bottom: 0;
    margin: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: $paper;
    &:not(:last-child) {
        border-right: 1px solid $paper;
    }
    &.swiper-pagination-bullet-active {
        background: rgba(255, 255, 255, 0.25);
        opacity: 1;
    }
    .pagination-label {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: left;
        // border: 1px solid red;
    }
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

.fullDeskQuote {
    font-size: 4rem !important;
    * {
        font-size: 4rem !important;
    }
}
.fullMobQuote {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
    * {
        font-size: 1.25rem !important;
        line-height: 1.875rem !important;
    }
}
.articleBody {
    a,
    a:visited,
    a:active {
        color: $primaryMain !important;
        font-weight: 500;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}

.customIndicator {
    background: 'red';
}

ul.MuiList-root.MuiMenu-list {
    max-height: 200px !important;
    overflow-y: auto !important;
}
.MuiMenu-paper {
    margin-top: 0.5rem !important;
    div {
        background: $grey5 !important;
    }
    box-shadow: none !important;
    border-radius: 1rem !important;
    overflow: hidden !important;
    max-height: 200px !important;
    overflow-y: auto !important;
    background: $grey5 !important;
    // background: rgba(255, 255, 255, 0.75) !important;
    // backdrop-filter: blur(1rem) !important;
}

ul.MuiList-root.MuiMenu-list[aria-labelledby='frontendSelect'] {
    max-height: 200px !important;
    overflow-y: auto !important;
    // background: rgba(0, 0, 0, 0) !important;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

@import 'mapbox-gl/dist/mapbox-gl.css';
