// paper & background
$paper: #fefefe;

// primary
$primaryLight: rgb(41, 40, 59);
$primaryMain: rgb(90, 82, 194);
$primaryDark: rgb(220, 217, 248);
$primary200: rgb(136, 127, 190);
$primary800: rgb(66, 65, 113);

// secondary
$secondaryLight: rgb(224, 255, 250);
$secondaryMain: rgb(95, 206, 185);
$secondaryDark: rgb(4, 92, 76);
$secondary200: rgb(142, 235, 218);
$secondary800: rgb(18, 158, 132);
$secondaryBg: rgb(0, 39, 32);

// secondary
$accentLight: rgb(255, 231, 191);
$accentMain: rgb(255, 184, 57);
$accentDark: rgb(168, 107, 0);

// success Colors
$successLight: rgb(171, 245, 223);
$success200: rgb(123, 235, 201);
$successMain: rgb(78, 188, 155);
$successDark: rgb(15, 156, 114);

// error
$errorLighter: rgb(255, 221, 227);
$errorLight: rgb(245, 155, 171);
$errorMain: rgb(239, 77, 107);
$errorDark: rgb(131, 21, 41);

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$subtleText: #a6a299; // unused, for frontend body text
$bodyText: #797166; // unused, for frontend body text

// invert all colors from the grey palette
$grey900: rgb(248, 251, 255);
$grey700: rgb(242, 246, 253);
$grey600: rgb(231, 240, 252);
$grey550: rgb(220, 230, 245);
$grey500: rgb(205, 217, 235);
$grey300: rgb(190, 204, 223);
$grey200: rgb(155, 176, 202);
$grey100: rgb(120, 144, 174);
$grey100rgba: 120, 144, 174;
$grey75: rgb(101, 124, 153);
$grey50: rgb(0, 0, 0);
$grey50rgba: 0, 0, 0;
$grey10: rgb(31, 27, 49);
$grey10rgba: 31, 27, 49;
$grey5: rgb(4, 15, 24);

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: rgb(201, 199, 223);
$darkPrimaryMain: rgb(90, 82, 194);
$darkPrimaryDark: rgb(91, 89, 131);
$darkPrimary200: rgb(137, 123, 216);
$darkPrimary800: rgb(66, 65, 113);

// secondary dark
$darkSecondaryLight: rgb(224, 255, 250);
$darkSecondaryMain: rgb(95, 206, 185);
$darkSecondaryDark: rgb(4, 92, 76);
$darkSecondary200: rgb(142, 235, 218);
$darkSecondary800: rgb(18, 158, 132);

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #4b5475;
$darkTextSecondary: #8492c4;

$darkGrey5: rgb(239, 244, 250);
$darkGrey10: rgb(251, 252, 255);
$darkGrey10rgba: 251, 252, 255;
$darkGrey50: rgb(243, 248, 255);
$darkGrey50rgba: 243, 248, 255;
$darkGrey75: rgb(220, 230, 245);
$darkGrey100: rgb(205, 217, 235);
$darkGrey100rgba: 205, 217, 235;
$darkGrey200: rgb(190, 204, 223); // outlines
$darkGrey300: rgb(155, 176, 202);
$darkGrey500: rgb(120, 144, 174);
$darkGrey550: rgb(101, 124, 153);
$darkGrey600: rgb(81, 103, 131);
$darkGrey700: rgb(62, 83, 111);
$darkGrey900: rgb(4, 15, 24);

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondaryBg: $secondaryBg;
    secondary800: $secondary800;

    // accent
    accentLight: $accentLight;
    accentMain: $accentMain;
    accentDark: $accentDark;
    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLighter: $errorLighter;
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey5: $grey5;
    grey10: $grey10;
    grey10rgba: $grey10rgba;
    grey50: $grey50;
    grey50rgba: $grey50rgba;
    grey75: $grey75;
    grey100: $grey100;
    grey100rgba: $grey100rgba;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey550: $grey550;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    darkGrey5: $darkGrey5;
    darkGrey10: $darkGrey10;
    darkGrey10rgba: $darkGrey10rgba;
    darkGrey50: $darkGrey50;
    darkGrey50rgba: $darkGrey50rgba;
    darkGrey75: $darkGrey75;
    darkGrey100: $darkGrey100;
    darkGrey100rgba: $darkGrey100rgba;
    darkGrey200: $darkGrey200;
    darkGrey300: $darkGrey300;
    darkGrey500: $darkGrey500;
    darkGrey550: $darkGrey550;
    darkGrey600: $darkGrey600;
    darkGrey700: $darkGrey700;
    darkGrey900: $darkGrey900;

    //Article colors
    bodyText: $bodyText;
    subtleText: $subtleText;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
