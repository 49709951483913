@font-face {
    font-family: 'Sequel Sans Medium Obl Disp';
    src: url('../fonts/SequelSans-MediumOblDisp.woff2') format('woff2'), url('../fonts/SequelSans-MediumOblDisp.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Heavy Obl Disp';
    src: url('../fonts/SequelSans-HeavyOblDisp.woff2') format('woff2'), url('../fonts/SequelSans-HeavyOblDisp.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Roman Disp';
    src: url('../fonts/SequelSans-RomanDisp.woff2') format('woff2'), url('../fonts/SequelSans-RomanDisp.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Light Obl Disp';
    src: url('../fonts/SequelSans-LightOblDisp.woff2') format('woff2'), url('../fonts/SequelSans-LightOblDisp.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Semi Bold Obl Disp';
    src: url('../fonts/SequelSans-SemiBoldOblDisp.woff2') format('woff2'), url('../fonts/SequelSans-SemiBoldOblDisp.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Heavy Disp';
    src: url('../fonts/SequelSans-HeavyDisp.woff2') format('woff2'), url('../fonts/SequelSans-HeavyDisp.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Medium Disp';
    src: url('../fonts/SequelSans-MediumDisp.woff2') format('woff2'), url('../fonts/SequelSans-MediumDisp.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Semi Bold Disp';
    src: url('../fonts/SequelSans-SemiBoldDisp.woff2') format('woff2'), url('../fonts/SequelSans-SemiBoldDisp.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Bold Disp';
    src: url('../fonts/SequelSans-BoldDisp.woff2') format('woff2'), url('../fonts/SequelSans-BoldDisp.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Black Obl Disp';
    src: url('../fonts/SequelSans-BlackOblDisp.woff2') format('woff2'), url('../fonts/SequelSans-BlackOblDisp.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Black Disp';
    src: url('../fonts/SequelSans-BlackDisp.woff2') format('woff2'), url('../fonts/SequelSans-BlackDisp.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Bold Obl Disp';
    src: url('../fonts/SequelSans-BoldOblDisp.woff2') format('woff2'), url('../fonts/SequelSans-BoldOblDisp.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Book Obl Disp';
    src: url('../fonts/SequelSans-BookOblDisp.woff2') format('woff2'), url('../fonts/SequelSans-BookOblDisp.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Oblique Disp';
    src: url('../fonts/SequelSans-ObliqueDisp.woff2') format('woff2'), url('../fonts/SequelSans-ObliqueDisp.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Light Disp';
    src: url('../fonts/SequelSans-LightDisp.woff2') format('woff2'), url('../fonts/SequelSans-LightDisp.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel Sans Book Disp';
    src: url('../fonts/SequelSans-BookDisp.woff2') format('woff2'), url('../fonts/SequelSans-BookDisp.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
